import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca init`}</strong>{` -- initialize the CA PKI`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca init
[--root=<file>] [--key=<file>] [--pki] [--ssh]
[--helm] [--deployment-type=<name>] [--name=<name>]
[--dns=<dns>] [--address=<address>] [--provisioner=<name>] 
[--admin-subject=<string>] [--provisioner-password-file=<file>] 
[--password-file=<file>] [--ra=<type>] [--kms=<type>] 
[--with-ca-url=<url>] [--no-db] [--remote-management] 
[--acme] [--context=<name>] [--profile=<name>] [--authority=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca init`}</strong>{` command initializes a public key infrastructure (PKI) to be
used by the Certificate Authority.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path of an existing PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` to be used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path of an existing key `}<inlineCode parentName="p">{`file`}</inlineCode>{` of the root certificate authority.`}</p>
    <p><strong parentName="p">{`--pki`}</strong>{`
Generate only the PKI without the CA configuration.`}</p>
    <p><strong parentName="p">{`--ssh`}</strong>{`
Create keys to sign SSH certificates.`}</p>
    <p><strong parentName="p">{`--helm`}</strong>{`
Generates a Helm values YAML to be used with step-certificates chart.`}</p>
    <p><strong parentName="p">{`--deployment-type`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` of the deployment type to use. Options are:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`standalone`}</strong>{`: An instance of step-ca that does not connect to any cloud services. You
manage authority keys and configuration yourself.
Choose standalone if you'd like to run step-ca yourself and do not want
cloud services or commercial support.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`linked`}</strong>{`: An instance of step-ca with locally managed keys that connects to your
Certificate Manager account for provisioner management, alerting,
reporting, revocation, and other managed services.
Choose linked if you'd like cloud services and support, but need to
control your authority's signing keys.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`hosted`}</strong>{`: A highly available, fully-managed instance of step-ca run by smallstep
just for you.
Choose hosted if you'd like cloud services and support.`}</p>
      </li>
    </ul>
    <p>{`More information and pricing at: `}<a parentName="p" {...{
        "href": "https://u.step.sm/cm"
      }}>{`https://u.step.sm/cm`}</a></p>
    <p><strong parentName="p">{`--name`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` of the new PKI.`}</p>
    <p><strong parentName="p">{`--dns`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The DNS `}<inlineCode parentName="p">{`name`}</inlineCode>{` or IP address of the new CA.
Use the '--dns' flag multiple times to configure multiple DNS names.`}</p>
    <p><strong parentName="p">{`--address`}</strong>{`=`}<inlineCode parentName="p">{`address`}</inlineCode>{`
The `}<inlineCode parentName="p">{`address`}</inlineCode>{` that the new CA will listen at.`}</p>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` of the first provisioner.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt the keys.`}</p>
    <p><strong parentName="p">{`--provisioner-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt the provisioner key.`}</p>
    <p><strong parentName="p">{`--with-ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the Step Certificate Authority to write in defaults.json`}</p>
    <p><strong parentName="p">{`--ra`}</strong>{`=`}<inlineCode parentName="p">{`type`}</inlineCode>{`
The registration authority `}<inlineCode parentName="p">{`type`}</inlineCode>{` to use. Currently "StepCAS" and "CloudCAS" are supported.`}</p>
    <p><strong parentName="p">{`--kms`}</strong>{`=`}<inlineCode parentName="p">{`type`}</inlineCode>{`
The key manager service `}<inlineCode parentName="p">{`type`}</inlineCode>{` to use to manage keys. Options are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`azurekms`}</strong>{`: Use Azure Key Vault to manage X.509 and SSH keys. The key URIs have
the following format `}<inlineCode parentName="li">{`azurekms:name=key-name;vault=vault-name`}</inlineCode>{`.`}</li>
    </ul>
    <p><strong parentName="p">{`--kms-root`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
The kms `}<inlineCode parentName="p">{`URI`}</inlineCode>{` used to generate the root certificate key. Examples are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`azurekms`}</strong>{`: azurekms:name=my-root-key;vault=my-vault`}</li>
    </ul>
    <p><strong parentName="p">{`--kms-intermediate`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
The kms `}<inlineCode parentName="p">{`URI`}</inlineCode>{` used to generate the intermediate certificate key. Examples are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`azurekms`}</strong>{`: azurekms:name=my-intermediate-key;vault=my-vault`}</li>
    </ul>
    <p><strong parentName="p">{`--kms-ssh-host`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
The kms `}<inlineCode parentName="p">{`URI`}</inlineCode>{` used to generate the key used to sign SSH host certificates. Examples are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`azurekms`}</strong>{`: azurekms:name=my-host-key;vault=my-vault`}</li>
    </ul>
    <p><strong parentName="p">{`--kms-ssh-user`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
The kms `}<inlineCode parentName="p">{`URI`}</inlineCode>{` used to generate the key used to sign SSH user certificates. Examples are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`azurekms`}</strong>{`: azurekms:name=my-user-key;vault=my-vault`}</li>
    </ul>
    <p><strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
The registration authority issuer `}<inlineCode parentName="p">{`url`}</inlineCode>{` to use.`}</p>
    <p>{`If StepCAS is used, this flag should be the URL of the CA to connect
to, e.g `}<a parentName="p" {...{
        "href": "https://ca.smallstep.com:9000"
      }}>{`https://ca.smallstep.com:9000`}</a></p>
    <p>{`If CloudCAS is used, this flag should be the resource name of the
intermediate certificate to use. This has the format
'projects/`}{`*`}{`/locations/`}{`*`}{`/caPools/`}{`*`}{`/certificateAuthorities/`}{`*`}{`'.`}</p>
    <p><strong parentName="p">{`--issuer-fingerprint`}</strong>{`=`}<inlineCode parentName="p">{`fingerprint`}</inlineCode>{`
The root certificate `}<inlineCode parentName="p">{`fingerprint`}</inlineCode>{` of the issuer CA.
This flag is supported in "StepCAS", and it should be the result of running:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate fingerprint root_ca.crt
4fe5f5ef09e95c803fdcb80b8cf511e2a885eb86f3ce74e3e90e62fa3faf1531
`}</code></pre>
    <p><strong parentName="p">{`--issuer-provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` of an existing provisioner in the issuer CA.
This flag is supported in "StepCAS".`}</p>
    <p><strong parentName="p">{`--credentials-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The registration authority credentials `}<inlineCode parentName="p">{`file`}</inlineCode>{` to use.`}</p>
    <p>{`If CloudCAS is used, this flag should be the path to a service account key.
It can also be set using the 'GOOGLE_APPLICATION_CREDENTIALS=path'
environment variable or the default service account in an instance in Google
Cloud.`}</p>
    <p><strong parentName="p">{`--no-db`}</strong>{`
Generate a CA configuration without the DB stanza. No persistence layer.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` of the context for the new authority.`}</p>
    <p><strong parentName="p">{`--remote-management`}</strong>{`
Enable Remote Management. Defaults to false.`}</p>
    <p><strong parentName="p">{`--acme`}</strong>{`
Create a default ACME provisioner. Defaults to false.`}</p>
    <p><strong parentName="p">{`--admin-subject`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`, `}<strong parentName="p">{`--admin-name`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`
The admin `}<inlineCode parentName="p">{`subject`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--profile`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` that will serve as the profile name for the context.`}</p>
    <p><strong parentName="p">{`--authority`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` that will serve as the authority name for the context.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      